import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppState } from "../App";
import { signOut } from "firebase/auth";
import { auth } from "../Firebase/firebase";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

// Material-UI Imports for Drawer
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";

const NavBar = ({ name, email }) => {
  const App = useContext(AppState);
  const navigate = useNavigate();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [banner, setBanner] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);
  const [isResearchesDropdownOpen, setIsResearchesDropdownOpen] =
    useState(false);

  // States for Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchBannerText = async () => {
      try {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, "banner"));
        if (!querySnapshot.empty) {
          const { text, link } = querySnapshot.docs[0].data();
          setBanner(text || ""); // Fallback to empty if `text` is null/undefined
          setBannerLink(link || "#"); // Fallback to default link
        }
      } catch (error) {
        console.error("Error fetching banner text:", error);
      }
    };

    fetchBannerText();
  }, []); // Dependency array is empty as this is a one-time fetch

  // Menu Items for Drawer
  // Navigation items
  const menuItems = useMemo(
    () => [
      { label: "Home", to: "/" },
      { label: "Courses", to: "/courses" },
      { label: "Placements", to: "/job-portal" },
      { label: "Forum", to: "/bqforum" },
      { label: "Workshop", to: "/bq-workshops" },
      { label: "Markets", to: "/stockMarketDashboard" },
    ],
    []
  );

  const resourcesDropdown = useMemo(
    () => [
      { label: "Masterclasses", to: "/masterclasses" },
      { label: "Blog", to: "/blogpage2" },
      { label: "Forum", to: "/bqforum" },
      { label: "Solutions", to: "/softwares" },
      { label: "Test Series", to: "/testseries" },
    ],
    []
  );

  const researchesDropdown = useMemo(
    () => [
      { label: "Data Downloader", to: "/dataDownloader" },
      { label: "Ticker Fundamental Data", to: "/fundamentalData" },
      { label: "Macroeconomics Data", to: "/macroeconomicsDashboard" },
      { label: "Microeconomics Data", to: "/microeconomicsDashboard" },
    ],
    []
  );

  const handleLogout = () => {
    signOut(auth);
    App.setUserName("");
    App.setIsLogin(false);
    App.setPurchased(null);
    navigate("/");
  };

  const handleBannerClose = useCallback(() => {
    if (isBannerVisible) setIsBannerVisible(false);
  }, [isBannerVisible]);

  const handleMobileMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleDrawerState = useCallback((isOpen) => {
    setDrawerOpen(isOpen);
  }, []);

  const handleMobileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    toggleDrawerState(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
    toggleDrawerState(false);
  };

  return (
    <>
      {/* banner */}
      {isBannerVisible && banner && (
        <div className="flex items-center justify-between bg-indigo-600 px-2 py-2 text-white ">
          <div className="flex-grow container mx-auto">
            <p className="text-lg font-medium text-center">
              {/* <Link className='text-white hover:text-gray-300' to='/masterclasses'> */}
              <a
                href={bannerLink}
                target="_blank"
                className="inline-block underline font-medium hover:text-gray-200"
              >
                <u>{banner}</u>
              </a>
              {/* </Link> */}
            </p>
          </div>
          <button
            aria-label="Dismiss"
            className="shrink-0 rounded-lg bg-black/10 p-1 ml-4 transition hover:bg-black/20"
            onClick={handleBannerClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
      {/* Navbar */}
      <nav>
        <div className="px-3 sm:px-2 header sticky z-10 top-0 text-lg items-center text-white font-lg border-b-2 border-gray-500">
          <div className="container mx-auto items-center flex justify-between">
            <div>
              <Link className="hover:text-indigo-400" to="/">
                <img
                  style={{ height: "6rem" }}
                  src="betaquant-logo.png"
                  alt=""
                />
              </Link>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex space-x-3 items-center lg:space-x-6">
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className="hover:text-indigo-400 text-sm lg:text-[1.15rem]"
                >
                  {item.label}
                </Link>
              ))}

              {/* Dropdown menus */}
              <div
                className="relative"
                onMouseEnter={() => setIsResourcesDropdownOpen(true)}
                onMouseLeave={() => setIsResourcesDropdownOpen(false)}
              >
                <button className="hover:text-indigo-400 text-sm lg:text-[1.15rem]">
                  Resources
                </button>
                {isResourcesDropdownOpen && (
                  <div className="absolute left-0 bg-white z-50 text-black text-base shadow-md rounded w-48">
                    {resourcesDropdown.map((item, index) => (
                      <Link key={index} to={item.to}>
                        <div className="px-4 py-2 hover:bg-indigo-100 z-50">
                          {item.label}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <div
                className="relative"
                onMouseEnter={() => setIsResearchesDropdownOpen(true)}
                onMouseLeave={() => setIsResearchesDropdownOpen(false)}
              >
                <button className="hover:text-indigo-400 text-sm lg:text-[1.15rem]">
                  Research
                </button>
                {isResearchesDropdownOpen && (
                  <div className="absolute left-0 bg-white z-20 text-black shadow-md text-base rounded w-48">
                    {researchesDropdown.map((item, index) => (
                      <Link key={index} to={item.to}>
                        <div className="px-4 py-2 hover:bg-indigo-100">
                          {item.label}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              {App.adminUser && App.isLogin && (
                <a className="">
                  <Link
                    className="hover:text-indigo-400 text-center text-sm lg:text-[1.15rem]"
                    to="/bqadmin/*"
                  >
                    Admin
                  </Link>
                </a>
              )}
            </div>

            {/* Hamburger Menu for Mobile */}
            <IconButton
              sx={{ display: { xs: "block", md: "none" }, color: "white" }}
              onClick={() => toggleDrawerState(true)}
            >
              <MenuIcon />
            </IconButton>

            {/* User Account Section */}
            <div className="flex items-center">
              <div className="text-5xl font-thin ml-2">|</div>
              <div className="ml-2 flex md:flex-row flex-col items-center justify-center text-center">
                {!App.isLogin ? (
                  <div className="p-1">
                    <button className="text-white bg-green-600 hover:bg-green-800 font-bold py-2 px-2 rounded-lg">
                      <Link className="hover:text-white" to="/login">
                        Login
                      </Link>
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col sm:flex-row justify-center items-center text-center">
                      <button
                        className=" ml-4 text-white"
                        onClick={handleMobileMenuOpen}
                      >
                        <Box
                          bgcolor="#4CAF50" // Set the background color to green
                          className="hover:bg-green-700"
                          borderRadius="20%" // Make it a circle
                          p={1} // Add some padding
                          display="inline-flex" // Make sure it's an inline element
                        >
                          <AccountCircleSharpIcon fontSize="medium" />
                        </Box>
                      </button>
                      <span
                        onClick={() => navigate("/dashboard")}
                        className="pl-1 hidden lg:block font-thin text-xs md:text-sm text-center cursor-pointer text-gray-300 hover:text-gray-100"
                      >
                        Dashboard
                      </span>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMobileMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div className="pl-2 py-3 text-sm text-gray-900 dark:text-white overflow-hidden">
                          <div>{name}</div>
                          <div
                            className="font-medium truncate"
                            style={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {email}
                          </div>
                        </div>
                        <Divider />
                        <div className="py-2">
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            onClick={() => handleMenuItemClick("/dashboard")}
                          >
                            Dashboard
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            onClick={() => handleMenuItemClick("/userprofile")}
                          >
                            Account Settings
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            className="pt-2"
                            onClick={handleLogout}
                          >
                            Sign Out
                          </MenuItem>
                        </div>
                      </Menu>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Drawer for mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List className="w-64">
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigate(item.to)}
            >
              {item.label}
            </ListItem>
          ))}
          <Divider />
          <div className="pl-4 font-bold">Resources</div>
          {resourcesDropdown.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigate(item.to)}
            >
              {item.label}
            </ListItem>
          ))}
          <Divider />
          <div className="pl-4 font-bold">Research</div>
          {researchesDropdown.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigate(item.to)}
            >
              {item.label}
            </ListItem>
          ))}          
        </List>
      </Drawer>
    </>
  );
};

export default NavBar;
